import {defineStore} from "pinia";
import {useTheme} from "vuetify";
import {computed, ref, watch} from "vue";
import {cookieStorage} from "@/utils/cookies.utils";


export const useThemeStore = defineStore('theme', () => {

    const { global } = useTheme()

    const theme = ref<string>(cookieStorage.getItem('hsp-theme') ?? 'light')
    const isDarkTheme = computed(() => theme.value === 'dark')

    const toggle = () => {
        theme.value = isDarkTheme.value ? 'light' : 'dark'
    }

    const onThemeChange = (value: string) => {
        global.name.value = value
        cookieStorage.setItem('hsp-theme', value)
    }

    watch(theme, onThemeChange, { immediate: true })

    return { isDarkTheme, toggle }

})
